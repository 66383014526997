<template>
    <div class="main-container">
        <div :class="['box',bgcolor,cursor]" @click="selected(nivel)">
            <div class="box__nivel">
                <div class="box__text">Nivel {{nivel}}</div>
                <div class="box__stars">
                    {{stars}}
                </div>
            </div>
            <img src="../assets/icons/fig-nivel.png" class="fig-level">
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
    name:'NivelCard',
    props:['nivel','bgcolor','activado'],
    data:()=>({
        cursor:''
    }),
    computed:{
        stars(){
            let star = '';
            for(let i=0;i<this.nivel;i++)
            {
                star = star + '⭐';
            }
            return star;
        }
    },
    methods:{
        ...mapMutations(['changeNivelSelected','changeNivelColor']),
        selected(nivel){
            if(this.activado)
            {
                this.changeNivelSelected(nivel)
                this.changeNivelColor('n'+nivel)
                this.$router.push('/nivel-detalle')
            }
            else{
                this.cursor="no-select"
            }
            
        }
    }
}
</script>
<style scoped>
.main-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.box{
    margin-top: 15px;
    margin-bottom: 15px;
    
    width: 90%;
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    padding: .5em 0em;
    cursor: pointer;
    transition: all .5s;
}
.box:hover{
    box-shadow: 0px 0px 15px white;
}
.no-select{
    cursor: not-allowed;
}
.box__nivel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1em;
}
.box__text{
    font-size: 3em;
    color:white;
}
.box__stars{
    font-size: 1.5em;
}
.bg-1{
    background-color: #9C04C4;
}
.bg-2{
    background-color: #1A0271;
}
.bg-3{
    background-color: #9E1981;
}
.bg-4{
    background-color: #B26B65;
}
.bg-5{
    background-color: #285D5C;
}
.fig-level{
    width: 20%;
}
@media (min-width: 900px) and (orientation: landscape) {
    .box__text{
        font-size: 2em;
    }
    .box__stars{
        font-size: 1em;
    }
    .fig-level{
        width: 30%;
        height: 80%;
    }
}
</style>>