<template>
  <div>
    <div v-if="!loaded">
      <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    </div>
    <div v-else class="home">
    <nav-bar sizetitle="md"></nav-bar>
    <div class="m-container">
      <img class="lion-teach" src="../assets/characters/leon profe1.png" alt="">
      <div class="speak-lion">Seleciona un nivel</div>
      <div class="nivel-card-container">
        <nivel-card nivel="1" bgcolor="bg-1" :activado="true" ></nivel-card>
        <nivel-card nivel="2" bgcolor="bg-2" :activado="false"></nivel-card>
        <nivel-card nivel="3" bgcolor="bg-3" :activado="false"></nivel-card>
        <nivel-card nivel="4" bgcolor="bg-4" :activado="false"></nivel-card>
        <nivel-card nivel="5" bgcolor="bg-5" :activado="false"></nivel-card>
      </div>
    </div>
    <div class="spacer-y"></div>
  </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import loader from "../components/Loader.vue";
import NavBar from '../components/NavBar.vue'
import NivelCard from '../components/NivelCard.vue'
import {auth,db} from '../firebase'
export default {
  name: 'Home',
  data:()=>({
    loaded:false
  }),
  components: {
    NavBar,
    NivelCard,
    loader
  },
  methods:{
    getCurrentUser(){
        return new Promise((resolve,reject)=>{
            const unsubscribe = auth.onAuthStateChanged(
              user =>{
                unsubscribe();
                resolve(user);
              },
              ()=>{
                reject();
              }
            );
        });
      },
    async getNameUser(){
     let user = await  this.getCurrentUser();
     let docRef = db.collection("usuarios").doc(user.uid);
      docRef.get().then((doc) => {
          if (doc.exists) {
              if(!doc.data().nombres)
              {
                this.$router.replace('/editar-datos')
              }
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              this.$router.replace('/editar-datos')
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
  },
  async mounted(){
    
    await this.getNameUser()
    this.$nextTick(()=>{
      this.loaded = true
    });
  },
}
</script>
<style>
.home{
  background-color:#6204BF;
  min-height: 100vh;
}
.spacer-y{
  height: 7em;
}
.lion-teach{
  display: none;
}
.speak-lion{
  display: none;
}
@media (min-width: 900px) and (orientation: landscape) {
  .nivel-card-container{
    display: grid;
    width: 100%;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 10em 10em;
    grid-column: 3 / 6;
  }
  .home{
    background-image: url('../assets/bg-desktop/bg-home.svg');
    background-position-x: -20px;
    background-position-y: -200px;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .m-container{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(5,20%);
    grid-template-rows: 80vh;
    align-items: center;
  }
  .lion-teach{
     display: block;
     width: 100%;
     height: 90%;
     object-fit: cover;
     transform: translateX(40%) translateY(5%);
  }
  .speak-lion{
    position: relative;
    top: -200px;
    display: block;
    text-align: center;
    width: 60%;
    background-color: whitesmoke;
    color: #343434;
    font-size: 2em;
    padding: 1em .5em;
    border-radius: 30px;
    transform: translateX(20%)
  }
  .spacer-y{
    display: none;
  }
}
</style>
